import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { resources } from "../../data/resources"
import { Link } from 'gatsby'

const Resources = () => (
  <Layout>
    <Seo title="Evolutionary astrology" />
    <h1>Resources</h1>
    <p>Below is a list of resources that I recommend for people new to EA.</p>

    <h2 className="mb-4 underline">Books</h2>
    {resources.books.map(type => (
      <section className="mb-8" key={type.title}>
        <h3>{type.title}</h3>
        {type.author ? <p className="italic">{type.author}</p> : null}
        {type.description ? <p>{type.description}</p> : null}
        {type.link ? <p>{type.link}</p> : null}
      </section>
    ))}
    <h2 className="mb-4 underline">Websites and Videos</h2>
    {resources.websitesAndVideos.map(type => (
      <section className="mb-8" key={type.title}>
        <h3>{type.title}</h3>
        {type.author ? <p>{type.author}</p> : null}
        {type.description ? <p>{type.description}</p> : null}
        {type.link ? <p><a>{type.link}</a></p> : null}
      </section>
    ))}

    <Link to="/evolutionary-astrology">Back to Articles</Link>

  </Layout>
)

export default Resources